@font-face {
  font-family: $mulish;
  src: url('#{$path-to-fonts}/Mulish/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $mulish;
  src: url('#{$path-to-fonts}/Mulish/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $mulish;
  src: url('#{$path-to-fonts}/Mulish/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $mulish;
  src: url('#{$path-to-fonts}/Mulish/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
