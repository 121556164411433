@import "src/assets/themes/user-variables";

.cardAssessment{
  :global(.MuiCardContent-root){
    padding: 1.5rem;
  }
}

.timeTotal{
  border: .0625rem dashed $secondary;
  padding: .375rem 1.125rem;
  border-radius: .75rem;
  display: flex;
  align-items: center;
  i{
    font-size: 1.5rem;
  }
  .text{
    font-size: 1.5rem;
  }
}

.textBlack11{
  color: #5E6278;
}

.borderColorGray16{
  border-color: $gray-16;
}

@media (min-width: 1200px) {
  .cardAssessment{
    :global(.MuiCardContent-root){
      padding: 3.125rem;
      &:last-child{
        padding-bottom: 3.125rem;
      }
    }
  }
}