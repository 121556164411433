.asset{
  background-color: $snow-12;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: .375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  &-lg{
    width: toRem(250);
    height: toRem(120);
  }
  &-78{
    min-width: toRem(78);
    width: toRem(78);
    height: toRem(78);
  }
}

.img-asset{
  min-width: 150px;
  width: 150px;
  height: 100px;
  overflow: hidden;
  border-radius: .375rem;
  position: relative;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.img-asset-sm{
    min-width: 90px;
    width: 90px;
    height: 60px;
  }
  &.circle{
    border-radius: 50%;
  }
  &.img-asset-90-90{
    min-width: 90px;
    width: 90px;
    height: 90px;
  }
  &.img-asset-32{
    min-width: toRem(32);
    width: toRem(32);
    height: toRem(32);
  }
  &.img-asset-60{
    min-width: toRem(60);
    width: toRem(60);
    height: toRem(60);
  }
  &.img-asset-78{
    min-width: toRem(78);
    width: toRem(78);
    height: toRem(78);
  }
}

.img-asset-box{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: toRem(10);
  padding: toRem(8);
  &.img-asset-32{
    min-width: toRem(32);
    width: toRem(32);
    height: toRem(32);
  }
  &.img-asset-50{
    min-width: toRem(50);
    width: toRem(50);
    height: toRem(50);
  }
  &.asset-bg{
    background-color: $snow-12;
  }
  &.asset-success{
    background-color: $green-15;
  }
  &.asset-warning{
    background-color: $orange-10;
  }

}

.profile-camera{
  height:1.5rem;
  background-color: rgba($black, .12);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}