@import "src/assets/themes/user-variables";
@import "src/assets/themes/mixins";
@import "src/assets/themes/extend";

.header {
  background-color: $white;
  border-bottom: toRem(1) solid $gray-1000;
  box-shadow: 0 toRem(1) toRem(1) rgba($white,.15);
  &:global(.navbar){
    padding-top: toRem(16);
    padding-bottom: toRem(16);
  }
  .navbarBrand{
    padding: 0 toRem(40) 0 0;
    border-right: toRem(2) solid $gray-1003;
    display: inline-block;
    margin-right: toRem(40)
  }
  .navs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .search {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .nav_link {
      display: inline-block;
      text-decoration: none;
      padding: toRem(8) toRem(16);
      font-family: $font-family-base;
      font-style: normal;
      font-size: toRem(14);
      line-height: toRem(18);
      color: $dark;
      border-radius: toRem(200);
      position: relative;
      &:global(.active){
        text-decoration: none;
        color: $white;
        background-color: $primary;
      }
    }
    .user_profile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
      span {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        line-height: toRem(18);
      }
      b {
        font-family: $font-family-base;
        font-style: normal;
        font-weight: 500;
        line-height: toRem(18);

      }
    }
  }
  .btnProfile{
    min-width: toRem(36);
    width: toRem(36);
    height: toRem(36);
    border-radius: 50%;
    background-color: $gray-20;
    color: $white;
    font-size: toRem(20);
    font-weight: $font-weight-medium;
    &:hover{
      background-color: $gray-20;
      color: $white;
    }

  }
}

.logoutButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: toRem(10) toRem(27);
  background: #F64E60;
  border-radius: toRem(6);
  margin-left: toRem(10);
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 600;
  font-size: toRem(12);
  line-height: toRem(14);
  text-align: center;
  color: #FFFFFF;

  @media (max-width: 768px) {
      padding: toRem(10) toRem(15);
  }
}

.menu{
  display: flex;
  margin-bottom: 0;
  li{
    margin-right: toRem(10);
  }
}

@media (max-width: 991px) {
  .navbar{
    &:global(.navbar-collapse){
      position: absolute;
      background-color: $white;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1000;
    }
    .navs{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: toRem(24) calc(5% + 1rem);
    }
    .menu{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      li{
        margin-right: 0;
        margin-bottom: toRem(10);
      }
    }
  }
}
