@import "src/assets/themes/user-variables";

* {
  box-sizing: border-box;
  font-family: "Overpass", sans-serif;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Overpass", sans-serif;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0 8px;
  background-color: #f5f8fa;
  border-radius: 6px;

  #tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
	
.tag {
	width: auto;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #8950fc;
	padding: 4px;
	font-size: 14px;
	list-style: none;
	border-radius: 6px;
	margin: 0 5px 0 0;
	background: #eee5ff !important;
	.tag-title {
	  color: #8950fc !important;
	  font-family: $font-family-base;
	  font-style: normal;
	  font-weight: 500;
	  font-size: 13px;
	  line-height: 15px;
	  padding-left: 8px;
	}
	.tag-close-icon {
	  display: block;
	  width: 16px;
	  height: 16px;
	  line-height: 16px;
	  text-align: center;
	  font-size: 14px;
	  margin-left: 1px;
	  color: #8950fc;
	  border-radius: 50%;
	  background: #fff;
	  cursor: pointer;
	}
  }
  }

  input {
    width: 100%;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    background-color: #f5f8fa;
    &:focus {
      outline: transparent;
    }
  }
}




@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}
