.title26{
  &.MuiTypography-root{
    font-size: 1.625rem;
  }
}

.card-assessment{
  .MuiCardContent-root{
    padding: 1.5rem;
  }
}

.time-total{
  border: .0625rem dashed $secondary;
  padding: .375rem 1.125rem;
  border-radius: .75rem;
  display: flex;
  align-items: center;
  i{
    font-size: 1.5rem;
  }
  .text{
    font-size: 1.5rem;
  }
}

.has-action{
  position: relative;
  .form-control{
    padding-right: 5rem;
  }
}

.input-action{
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}

.question-wrap{
  max-width: 900px;
  margin: 0 auto;
}

.drawer-btn{
  min-width: 77px;
}

.video-list{
  li{
    padding: .625rem 0;
    border-bottom: .0625rem solid $gray-12;
  }
}

@media (min-width: 1200px) {
  .card-assessment{
    .MuiCardContent-root{
      padding: 3.125rem;
      &:last-child{
        padding-bottom: 3.125rem;
      }
    }
  }
}