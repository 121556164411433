// color
$black: #000;
$white: #fff;
$dark: #333333;
$secondary: #8C8C8C;
$success: #29CC97;
$danger: #F64E60;
$primary: #625AFA;
$warning: #FFC107;
$info: #8950FC;

$black-10: #3F4254;
$black-11: #5E6278;
$black-12: #181C32;
$black-13: #313042;
$black-14: #9099A3;
$black-15: #434254;
$black-16: #1F1E2E;

$green-11: #1BC5BD;
$green-12: #10B759;
$green-13: #0BB783;
$green-14: #D7F9EF;
$green-15: #93D676;

$blue-11: #8950FC;
$blue-12: #009EF6;
$blue-13: #EEE5FF;
$blue-14: #3699FF;
$blue-15: #403E5D;
$blue-16: #F1FAFF;


$snow-11: #E7ECF0;
$snow-12: #F3F6F9;

$orange-10: #FFF7E7;

$gray-11: #B5B5C3;
$gray-12: #E4E6EF;
$gray-13: #7E8299;
$gray-14: #F5F8FA;
$gray-15: #D8DFE3;
$gray-16: #E5EAEE;
$gray-17: #DCDEEC;
$gray-18: #D9D9D9;
$gray-19:  #EFF2F5;
$gray-20: #979797;
$gray-21: #DDE4E9;

//new color system
$gray-1000: #E3E3E3;
$gray-1001: #D1D1D1;
$gray-1002: #EFEFEF;
$gray-1003: #8C8C8C;

$blue-1000: #E1F6FF;
$blue-1100: #00A0F6;

//

$orange-10: #FFD3BD;
$red-10: #FFF2F4;

$yellow-11: #FFA800;
$yellow-12: #FFF4DE;

$red-11: #FFE2E5;



// font family
$poppins: 'Poppins';
$roboto: 'Roboto';
$mulish: 'Mulish';
$font-family-base: $mulish;
$font-family-roboto: $roboto;

//font-size
$font-size-xs: .75rem;
$font-size-sm: .875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-xxl: 1.5rem;

//font-weight
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

$path-to-fonts: '../../fonts' !default;