@import "src/assets/themes/user-variables";

.cardHeader{
  background-color: $gray-14;
  border-bottom: .0625rem solid $gray-16;
  padding: .625rem 1.25rem;
}
.card{
  &:global(.MuiCard-root){
    border: .0625rem solid $gray-16;
    border-radius: .375rem;
  }
}

.textBlack11{
  color: $black-11;
}

.selectLanguage{
  position: absolute;
  top: .5rem;
  right: 0;
  z-index: 1;
}

.testOutputPanel{
  position: relative;
  background-color: rgba($white, .12);
  .outputTop{
    padding: .75rem 1.5rem;
  }
  .outputBody{
    height: 15rem;
    overflow: auto;
    padding: .75rem 1.5rem;
  }
}
.codeWrap{
  min-height: 15rem;
  overflow: auto;
}

@media (min-width: 768px) {
  .leftDivider{
    border-left: .0625rem solid $gray-16;
  }
}