.btn-toggle{
  position: relative;
  padding-right: 2.5rem;
  padding-left: 1.125rem;
  &.MuiButtonBase-root{
    padding-right: 2.5rem;
    padding-left: 1.125rem;
    justify-content: left;
    &.MuiButton-sizeSmall{
      height: 2.125rem;
    }
  }
  .toggle-icon{
    @extend %absoluteVCenter;
    right: 1.125rem;
    color: $gray-13;
    pointer-events: none;
  }
  &.btn-sm{
    padding-left: .875rem;
    height: 2.5rem;
    padding-right: 2.25rem;
    .toggle-icon{
      right: .875rem;
    }
  }
}
.multiSelect{
  .MuiOutlinedInput-notchedOutline{
    top: toRem(-10);
  }
  .MuiSelect-multiple{
    padding: 7.5px 2.5rem 7.5px 1.125rem !important;
  }
  .toggle-icon{
    @extend %absoluteVCenter;
    right: 1.125rem;
    color: $gray-13;
    pointer-events: none;
  }
}
.menu-item{
  &.MuiButtonBase-root{
    &.MuiMenuItem-root{
      padding: 0 1.5rem;
      margin-bottom: 1.25rem;
      &:hover{
        background-color: transparent !important;
      }
      &.Mui-focusVisible{
        background-color: transparent !important;
      }
      &.Mui-selected{
        &:hover{
          background-color: transparent !important;
        }
      }
    }
  }
}
