@import "src/assets/themes/mixins";
.fontSize11{
  font-size: toRem(11);
}
.commentList{
  li{
    margin-bottom: 1.25rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
}