.title {
  width: 100%;
  background: #f5f8fa;
  border-radius: 6px;
  justify-content: center;
  padding: 10px;
  // margin-bottom: 10px;
}
.textArea {
  width: 100%;
  padding: 5px;
}
.numberField {
  background: #f5f8fa;
  border-radius: 6px;
  padding: 10px;
  margin-left: 10px;
}

.container {
  display: flex;
  flex: 1;
}
.containerMulti {
  display: flex;
  flex: 1;
  align-items: center;
}
