@import "src/assets/themes/user-variables";
@mixin bordered($color) {
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #464e5f;
  
    &::before {
      content: "O";
      top: 0;
      left: 15px;
      margin-right: 10px;
      color: $color;
      transform: scale(2.5);
    }
  }
  
  .mainContainer {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  
    .Container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f5f8fa;
      box-sizing: border-box;
      padding: 40px 0;
      width: 100vw;
  
      .itemContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        gap: 40px;
        margin-top: 20px;
        .releventText {
          flex: 1;
          height: 130px;
          background-color: #ffffff;
          // border: 1px solid black;
          .text {
            width: 100%;
            height: 130px;
            padding: 5px;
            // background-color: #00a3ff;
          }
        }
        .timeContainer {
          flex: 1;
          background-color: #ffffff;
        }
        .difficultyLevel {
          flex: 0.5;
          background-color: #ffffff;
        }
      }
  
      .questionContainer {
        display: flex;
        flex-direction: row;
        // height: 50%;
        width: 90%;
        gap: 40px;
        justify-content: center;
        flex: 1;
  
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
  
        .left {
          // flex: 0.9;
          flex: 1;
          display: flex;
          // justify-content: flex-start;
  
          justify-content: center;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
          border-radius: 12px;
  
          .question {
            width: 90%;
            align-self: center;
            color: #212121;
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            padding-top: 30px;
          }
  
          .input {
            align-self: center;
            width: 90%;
            min-height: 227px;
            // background: #f5f8fa;
            border-radius: 6px;
            margin-bottom: 40px;
            .title {
              width: 100%;
              background: #f5f8fa;
              border-radius: 6px;
              // size: small;
              justify-content: center;
              // align-items: center;
              padding: 10px;
              margin-bottom: 10px;
              // margin-left: 10px
            }
          }
  
          .category {
            align-self: center;
            width: 90%;
            min-height: 50px;
            border-radius: 6px;
            background-color: #f5f8fa;
            margin-bottom: 40px;
           
          }
          .languages{
            align-self: center;
            width: 90%;
            min-height: 50px;
            border-radius: 6px;
            background-color: #f5f8fa;
            margin-bottom: 40px;
            padding: 10px;
            .parameterName {
              border: 1px solid black;
              border-radius: 6px;
              width: 130px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
            }
          }
        }
  
        .right {
          flex: 0.8;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
          border-radius: 12px;
          padding: 30px;
  
          .rightText {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
  
            span.heading {
              font-family: $font-family-base;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
              color: #212121 !important;
            }
  
            span.instructinHeading {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #3f4254 !important;
            }
          }
  
          .answers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
  
            .radio {
              flex: 1;
              .bottomItemContainer {
                display: flex;
                flex-direction: column;
                .pointsInfoText {
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 5px 10px;
                  gap: 10px;
  
                  width: 100%;
                  height: 34px;
                  margin: 0px 0px 20px 0px;
  
                  background: #fff7e7;
                  border: 1px dashed #ffa800;
                  border-radius: 6px;
                }
              }
  
              .options {
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                .optionSelect {
                  background-color: #f5f8fa;
                }
              }
  
              .addButton {
                align-items: center;
                padding: 10px 16px;
                gap: 5px;
                background: #f1faff;
                border-radius: 6px;
  
                font-family: $font-family-base;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                /* identical to box height */
  
                text-align: center;
  
                color: #00a3ff;
              }
  
              .label {
                font-family: $font-family-base;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #5e6278;
                text-align: right;
              }
            }
  
            .inputBox {
              width: 100%;
              background: #f5f8fa;
              border-radius: 6px;
              size: small;
              justify-content: center;
              padding: 5px;
            }
  
            input::placeholder {
              text-indent: 10px;
            }
  
            .inputCounter {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 56px;
              background: #f5f8fa;
              border-radius: 6px;
              padding: 5px;
              margin-left: 20px;
              text-align: center;
            }
  
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f3f6f9;
              border-radius: 6px;
              padding: 10px;
              height: 42px;
              margin-left: 20px;
            }
          }
  
          .warning {
            box-sizing: border-box;
  
            /* Auto layout */
  
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 10px;
            gap: 10px;
            background: #fff7e7;
            border: 1px dashed #ffa800;
            border-radius: 6px;
            margin-bottom: 30px;
  
            p {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 24px;
              color: #3f4254;
              margin: 0;
            }
          }
        }
      }
  
      .bottomInfo {
        margin-top: 70px;
        width: 90%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
  
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
  
        .element {
          display: flex;
          align-items: center;
          height: 86px;
          max-width: 480px;
          background-color: #ffffff;
          border-radius: 12px;
          padding: 0 30px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right;
  
          @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
              background-image: url("../../Files/img/bg-#{$i}.png");
            }
          }
  
          // .text {
          //   font-family: "Poppins";
          //   font-style: normal;
          //   font-weight: 600;
          //   font-size: 20px;
          //   line-height: 26px;
          //   color: #464e5f;
          // }
  
          .text {
            font-family: $font-family-base;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #464e5f;
            margin: 0;
          }
          .circle {
            @include bordered(#f64e60);
          }
  
          .circle2 {
            @include bordered(#1bc5bd);
          }
  
          .circle3 {
            @include bordered(#8950fc);
          }
        }
      }
    }
  }
  
  button {
    border-style: none;
    cursor: pointer;
  }
  