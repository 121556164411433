.badge{
  font-size: 0.8125rem;
  font-weight: $font-weight-medium;
  border-radius: .375rem;
  padding: .5rem .625rem;
  line-height: 1rem;
  &.badge-soft-warning{
    color: $yellow-11;
    background-color: $yellow-12;
  }
  &.badge-soft-danger{
    color: $danger;
    background-color: $red-11;
  }
  &.badge-soft-success{
    color: $green-13;
    background-color: $green-14;
  }
  &.badge-soft-primary{
    color: $blue-11;
    background-color: $blue-13;
  }
}
.MuiChip-root{
  &.chip{
    border: toRem(1) solid $gray-1001;
  }
}
.badge-white{
  &.MuiChip-root{
    background-color: rgba($white,.5);
    color: $dark;
    .MuiChip-deleteIcon{
      color: $gray-1001;
    }
    &:hover{
      background-color: rgba($white,.5);
      .MuiChip-deleteIcon{
        color: $dark;
      }
    }
  }
}