.custom-table{
  thead{
    background-color: $snow-12;
  }
  th{
    color: $secondary;
    font-weight: $font-weight-medium;
    white-space: nowrap;
    font-size: toRem(14);
    padding-top: toRem(10);
    padding-bottom: toRem(10);
    text-transform: uppercase;
  }
  td{
    font-size: toRem(14);
    white-space: nowrap;
    border-color: $gray-1001;
    padding-top: toRem(10);
    padding-bottom: toRem(10);
  }
  .table-title{
    font-size: toRem(16);
  }
  &.without-border{
    th{
      background-color: $gray-14;
      &:first-child{
        border-bottom-left-radius: .75rem;
        border-top-left-radius: .75rem;
      }
      &:last-child{
        border-bottom-right-radius: .75rem;
        border-top-right-radius: .75rem;
      }
    }
    th,td{
      border-bottom: 0;
    }
    &.has-dashed-body{
      td{
        border-bottom: .0625rem dashed $gray-1001;
      }
      tr{
        &:last-child{
          td{
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.search-header{
  border-bottom: toRem(1) solid $gray-1001;
  min-height: toRem(60);
  .form-control{
    border: 0;
    width: 100%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    height: 100%;
    &:focus{
      box-shadow: none;
      border: 0;
    }
  }
}