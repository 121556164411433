@import "src/assets/themes/user-variables";
.ButtonStyles {
  text-transform: none;
  font-family: $font-family-base;
  span{
    font-family: $font-family-base;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
}


.side-drawer {
  /* In commentaries are the way to make it slide from left to right */
  position: fixed;
  height: 100%;
  background: #ffffff;
  top: 0;
  right: 0;
  /* right: 100%; */
  width: 25%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  /* transform: translateX(0%); */
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
  /* transform: translateX(100%); */
}

.DrawerContainer {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  height: 100vh;
  overflow-y: scroll;
}
.inviteCandidates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.customFonts {
  font-size: 18px;
  font-weight: bold;
  font-family: $font-family-base;
}
#border {
  border-bottom: 1px solid #e5eaee;
  margin-top: 20px;
}
.publicLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .TextField {
    background-color: #f5f8fa;
    padding: 4px;
    padding-left: 5px;
    border-radius: 1.5px;
    width: 60%;
  }
}
.inputFields {
  .TextField {
    background-color: #f5f8fa;
    padding: 4px;
    padding-left: 5px;
    border-radius: 1.5px;
    width: 100%;
  }
}

#removeButton {
  background-color: #aa4a44;
  width: 100%;
  font-family: $font-family-base;
  text-transform:none;
}
.inviteAddMoreButtonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}