/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');

.App {
  background-color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

._container {
  width: 90%;
  margin: 0 5%;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

//label {
//  font-size: 14px;
//  font-weight: 600;
//  color: rgb(34, 34, 34);
//}

.nav-tabs .nav-link {
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  padding: 15px 12px;
  width: 242px;
  border-radius: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3F4254;
  margin: 10px 0;

}

.nav-tabs .nav-link.active {
  background: #0094FF;
  color: #FFFFFF;
}