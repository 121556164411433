// global styles
* {
    box-sizing: border-box;
}

body {
    font-family: $font-family-base;
    color: $dark;
    background-color: $snow-12;
}

.font-family-roboto{
    font-family: $font-family-roboto !important;
}

.text-decoration-none{
    text-decoration: none !important;
}

.text-center{
    text-align: center !important;
}

.text-danger{
    color: $danger !important;
}

.text-black-11{
    color: $black-11 !important;
}

.flex-wrap{
    flex-wrap: wrap !important;
}

.font-size-lg{
    font-size: $font-size-lg !important;
}

.font-size-base{
    font-size: $font-size-base !important;
}

.font-size-15{
    font-size: 0.9375rem !important;
}

.font-size-sm{
    font-size: $font-size-sm !important;
}

.font-size-xs{
    font-size: $font-size-xs !important;
}

.font-weight-medium{
    font-weight: $font-weight-medium !important;
}

.font-weight-semi-bold{
    font-weight: $font-weight-semi-bold !important;
}

.font-weight-normal{
    font-weight: $font-weight-normal !important;
}

.font-weight-bold{
    font-weight: $font-weight-bold !important;
}

//headings
.heading1{
    font-size: toRem(26) !important;
}

.heading2{
    font-size: toRem(22) !important;
}

.warning-wrap{
    position: relative;
    border-radius: .375rem;
    background-color: $orange-10;
    padding: .5rem .75rem .5rem 2.5rem;
    color: $black-10;
    .icon-left{
        position: absolute;
        left: .75rem;
        top: .375rem;
    }
}

.page-spacing{
    padding-right: 5%;
    padding-left: 5%;
}