@import "src/assets/themes/user-variables";

//.questionWrap{
//  max-width: 900px;
//  margin: 0 auto;
//}
.timeLimit{
  width: 5rem;
}
.cardDashed{
  &:global(.MuiCard-root){
    border: .0625rem dashed $gray-15;
    border-radius: .375rem;
    background-color: rgba($gray-14, .4);
  }
}
.hasAction{
  position: relative;
  :global(.form-control){
    padding-right: 5rem;
  }
}

.inputAction{
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
}