@import "src/assets/themes/user-variables";
@import "src/assets/themes/mixins";
@import "src/assets/themes/extend";

.loginHeader{
    position: fixed;
    background-color: $black-16;
    padding: toRem(20) 0;
    width: 100%;
}

.heading30{
  &:global(.MuiTypography-root){
    font-size: toRem(30);
  }
}

.heading24{
  &:global(.MuiTypography-root){
    font-size: toRem(24);
  }
}

.loginPanel{
    min-height: 100vh;
}

.loginBanner{
    height: 100vh;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.loginForm{
    max-width: toRem(450);
    width: 100%;
  &.forgotPassword{
    max-width: toRem(600);
  }
}

.dividerWithText{
    position: relative;
    .divider{
        left: 0;
        @extend %absoluteVCenter;
        z-index: 0;
        width: 100%;
        border-color: $gray-15;
        opacity: 1;
    }
    .text{
        position: relative;
        z-index: 1;
        background-color: $white;
        padding: 0 toRem(8);
    }
}

.google-btn {
    width: 184px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  }
  
  .google-btn .google-icon-wrapper {
    position: absolute;
    width: 40px;
    height: 42px;
    background-color: #fff;
    border-radius: 5px 0px 0px 5px;
  }
  .google-btn .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .google-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
