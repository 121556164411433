@mixin bordered($color) {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #464E5F;

  &::before {
    content: "O";
    top: 0;
    left: 15px;
    margin-right: 10px;
    color: $color;
    transform: scale(2.5);
  }
}

@mixin card() {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
  border-radius: 12px;
  padding: 30px;
}

@mixin imgStyle() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #F3F6F9;
  border-radius: 12px;

  img {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: auto;
      padding: 0 10px;
    }
  }
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f8fa;
  // height: 100vh;
  padding: 40px 0;

  .icon {
    width: 32px;
    height: 32px;
    background: #F3F6F9;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
  }

  h2 {
    color: #212121;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  h6 {
    color: #B5B5C3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    white-space: nowrap;
  }

  .infoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // height: 50%;
    width: 90%;
    gap: 40px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .left {
      flex: 1.5;
      @include card();

      .indevidualCandidates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .dp {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F3F6F9;
          border-radius: 6px;
          padding: 15px;
          margin: 0 10px;

          p {
            width: 16px;
            height: 36px;
            margin: 0;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height */


            color: #FF415B;
          }

          img {
            width: 100%;
          }
        }

        b {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          white-space: nowrap;
          color: #464E5F;
        }

        span {

          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          /* identical to box height */

          text-align: right;

          color: #B5B5C3;
        }

        .additionalInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
          }
        }
      }
    }

    .right {
      flex: 1;
      @include card();

      .widgetsContainer {
        .widget {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .stackPic {
            @include imgStyle();
          }

          span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #80808F;
            background: #F3F6F9;
            border-radius: 6px;
            padding: 5px 7px;
          }
        }
      }

      .curveImg {
        margin: -30px;
        margin-top: 0;

      }
    }
  }

  .bottomCard {
    @include card();
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 40px 0;

    .saveButton {
      padding: 10px 27px;
      background: #3699FF;
      border-radius: 6px;
      margin-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #FFFFFF;

      @media (max-width: 768px) {
        padding: 10px 15px;
      }
    }


    table {
      thead {
        th {
          padding: 0.75rem 0;
          background-color: #ffffff;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 400;
          text-align: left;
          text-transform: uppercase;
          border-bottom-width: 1px;
          border-color: #B5B5C3;
        }
        .action {
          padding: 0.75rem 0;
          background-color: #ffffff;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 400;
          text-align: right;
          text-transform: uppercase;
          border-bottom-width: 1px;
          border-color: #b5b5c3;
        }
      }

      td {
        border-bottom-width: 1px;
        border-color: #B5B5C3;

        h6 {
          margin: 0 !important;
        }

        .icons {
          span {
            color: #B5B5C3 !important;
            font-family: 'Poppins';
            font-style: normal;
            margin: 0 12px 0 6px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
          }

          display: flex;
          flex-direction: row;
          gap: 5px;

          .tableInfo {
            display: flex;
            flex-direction: row;
            gap: 5px;
          }
        }

        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #464E5F;
        }




        .avatar {
          @include imgStyle();

        }
      }
    }
  }


}

button {
  border-style: none;
  cursor: pointer;
}