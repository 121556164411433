@import "src/assets/themes/user-variables";
@import "src/assets/themes/mixins";

@mixin menuStyle() {
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
  font-size: toRem(14);
  color: $dark;
}

.header {
  padding: toRem(8);
  background-color: $white;
  border-bottom: toRem(1) solid $gray-1000;
  .container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-left: 5%;

  }
}
.menu {
  span {
    @include menuStyle();
  }

  small {
    @include menuStyle();
    margin: 0 14px;
  }
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column !important;

    .buttonsWrap {
      justify-content: center !important;
    }
  }
}
