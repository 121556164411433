@mixin buttonStyle($background) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 27px;
  gap: 5px;
  // height: 34px;
  background: $background;
  border-radius: 6px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;

  &:disabled {
    opacity: 0.8;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
}

.m_containter {
  min-height: 70px;
  background: #313042;
  padding: 0 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .steps {
    // min-width: 50vw;
    margin: 10px 0;

    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    span {
      white-space: nowrap;
      color: white;
      margin: 5px 5px 0 0;
    }
  }

  .buttons {
    padding: 5px;

    @media screen and (max-width: 768px) {
      justify-content: center !important;
    }

    button {
      border-style: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancelButton {
      @include buttonStyle(#f64e60);
    }

    .saveButton {
      @include buttonStyle(#3699ff);
    }
  }
}

._cardContainer {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 36px;
  max-width: 770px;
  margin:  0 auto;

  h1 {
    color: #181c32;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.02em;
    white-space: normal;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #181c32;
    margin-top: 40px;
  }

  span {
    margin: 5px 0;
  }

  a {
    color: #00a3ff;

    &:hover {
      color: #2467ad;
    }
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #13263c;
  }

  .inputBox {
    width: 100%;
    background: #f5f8fa;
    border-radius: 6px;
    size: small;
    justify-content: center;
    padding: 5px;
  }

  .selection {
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
      color: #181c32;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #b5b5c3;
    }

    .selectedType {
      box-sizing: border-box;
      background: #f1faff;
      border: 1px dashed #00a3ff;
      border-radius: 9px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding: 30px;
      gap: 15px;
      flex-direction: row;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .unselectedType {
      box-sizing: border-box;
      background: rgba(245, 248, 250, 0.4);
      border: 1px dashed #b5b5c3;
      border-radius: 9px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      padding: 30px;
      gap: 15px;
      margin: 20px 0;
      flex-direction: row;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  .addButton {
    align-items: center;
    padding: 10px 16px;
    gap: 5px;
    background: #f1faff;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #00a3ff;
    margin: 14px 0;
  }

  .backButton {
    @include buttonStyle(#f5f8fa);
    color: #7e8299;
    margin: 0;
  }

  .saveButton {
    @include buttonStyle(#3699ff);
  }

  .flexResponsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .SkillIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: #f5f8fa;
      border-radius: 12px;

      img {
        width: 50%;
        height: 50%;
      }
    }

    .estimatedTime {
      background: #ffffff;
      border: 1px dashed #e4e6ef;
      border-radius: 6px;
      padding: 10px 15px;

      h3 {
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 23px;
        color: #3f4254;
        margin: 0;
      }
    }
  }

  table {
    tbody {
      tr {
        border-top: 1px dashed #e4e6ef;

        td {
          padding: 15px 0;

          .icon {
            width: 32px;
            height: 32px;
            background: #f3f6f9;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              background: "#CF485A";
            }
          }
        }

        .expYear {
          padding: 6px 11.5px;
          background: #fff5f8;
          border-radius: 6px;
        }
      }
    }
  }
}

.helpIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  margin-left: 10px;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 5px;

  .tableInfo {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}

@media (min-width: 1200px) {
  ._cardContainer{
    padding: 50px 130px;
  }
}
