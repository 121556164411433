.accordion-wrap{
  &.MuiAccordion-root{
    box-shadow: none;
    border: .0625rem solid $gray-15;
  }
  .MuiAccordionSummary-root{
    padding: .625rem 1rem;
    min-height: auto;
    &.Mui-expanded{
      min-height: auto;
      .MuiAccordionSummary-content{
        margin: 0;
      }
    }
  }
  .MuiAccordionSummary-content{
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }
  .MuiAccordionDetails-root{
    background-color: $gray-14;
  }
  &.outline-white{
    &.MuiAccordion-root{
      &::before{
        display: none;
      }
      background-color: transparent;
      border-radius: toRem(8);
      box-shadow: none;
      border: .0625rem solid $white;
      overflow: hidden;
    }
    .MuiAccordionSummary-root{
      padding: toRem(10);
      background-color: $white;
    }
    .MuiAccordionDetails-root{
      background-color: transparent;
      padding: toRem(10);
    }
  }
}
.card-list{
  li{
    margin-bottom: toRem(16);
    &:last-child{
      margin-bottom: 0;
    }
  }
}