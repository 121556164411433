@import "src/assets/themes/user-variables";
@import "src/assets/themes/mixins";

.selectedPanel{
  background-color: $gray-21;
  border-top-left-radius: toRem(12);
  border-top-right-radius: toRem(12);
  @media (min-width: 768px) {
    height: calc(100vh - 5.75rem);
    overflow-y: auto;
  }
}
.selectedCard{
  &:global(.MuiCard-root){
    position: relative;
    overflow: visible;
  }
  :global{
    .btn-x{
      position: absolute;
      right: toRem(-8);
      top: toRem(-8);
    }
  }
}
.emptyCard{
  &:global(.MuiCard-root){
    background-color: rgba($white,.4);
  }
  :global(.MuiCardContent-root){
    min-height: toRem(185);
  }
}