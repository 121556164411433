.functionSignatureContainer {
  display: flex;
  flex-direction: column;

  .function {
    width: 100%;
    display: flex;
    margin-top: 5px;
    gap: 15px;
    .textField {
      flex: 0.6;
    }
    .selectField {
      flex: 0.4;
    }
  }
}

.parameterContainer{
    overflow-y: auto;
    max-height: 500px;
    .parameters {
        width: 100%;
        display: flex;
        margin-top: 20px;
        gap: 15px;
   
        .textField {
          flex: 0.6;
        }
        .selectField {
          flex: 0.4;
        }
      }
}
