.btn{
  &.btn-link{
    min-width: auto;
    color: $blue-12;
  }
  border-radius: .375rem;
}

button:disabled,
button[disabled]{
  border: 1px solid rgba($dark,.4);
  background-color: $white;
  color: $secondary;
  cursor: default;
}

.btn-light{
  background-color: $white;
  color: $dark;
  border-color: $gray-12;
  &.btn-toggle{
    .toggle-icon{
      color: $dark;
    }
  }
}

.btn-snow{
  background-color: $snow-12;
  color: $gray-13;
  &:hover, &.btn:first-child:hover{
    background-color: $snow-12 !important;
  }
}

.btn-dark{
  &.MuiButton-outlinedSecondary{
    border-color: $dark;
    color: $dark;
  }
}

.btn-light-primary{
  background: $blue-16;
  color: $blue-12;
  border: .0625rem solid $blue-12;
  &:hover,&:active{
    background: $blue-16 !important;
    border: .0625rem solid $blue-12 !important;
    color: $blue-12 !important;
  }
}

.btn-lg{
  height: toRem(48);
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.btn-x{
  width: toRem(26);
  height: toRem(26);
  border-radius: 50%;
  border: .0625rem solid $danger;
  color: $danger;
  background-color: $red-10;
  padding: 0;
  &:hover,&:active{
    background-color: $red-10 !important;
    border: .0625rem solid $danger !important;
    color: $danger !important;
  }
  .close-icons{
    font-size: toRem(16);
  }
  &.top-right{
    position: absolute;
    top: toRem(-8);
    right: toRem(-8);
  }
}