.video-record{
  width: 100%;
  height: 360px;
  border-radius: .375rem;
  position: relative;
  overflow: hidden;
  background-color: $snow-12;
  .video{
    width: 100%;
    height: 100%;
  }
  .record-control-bar{
    @extend %absoluteBottom;
    background-color: rgba($black, .5);
    width: 100%;
    min-height: 50px;
  }
  .stream-time{
    @extend %absoluteVCenter;
    right: 1.875rem;
  }
}
.record-circle{
  &::before{
    content: '';
    width: 1rem;
    height: 1rem;
    border: .0625rem solid $dark;
    border-radius: 50%;
    margin-right: .5rem;
  }
}