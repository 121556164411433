@import "../../../../assets/themes/user-variables";
.questionWrap{
  max-width: 56.25rem;
  margin: 0 auto;
}

.questionContent{
  min-height: 3rem;
  position: relative;
  z-index: 1;
}

.hasCircle{
  position: relative;
  padding-left: 1.5rem;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    border-wdth: 3px;
    border-style: solid;
  }
  &.danger{
    &::before{
      border-color: $danger;
    }
  }
  &.success{
    &:before{
      border-color: $green-11;
    }
  }
  &.primary{
    &:before{
      border-color: $blue-11;
    }
  }
}

.imgShapes{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right;
}