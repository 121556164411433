@import "src/assets/themes/user-variables";
@import "src/assets/themes/extend";

.uploadCircle{
  width: 5.875rem;
  height: 5.875rem;
  border: .0625rem solid rgba($gray-17,.6);
  border-radius: 50%;
  padding: 1.25rem;
  position: relative;
  &::before{
    content: '';
    @extend %absoluteCenter;
    width: calc(100% - 1.25rem);
    height: calc(100% - 1.25rem);
    border: 0.1875rem solid rgba($gray-13, .5);
    border-radius: 50%;
  }
  :global(.icon-arrow-solid-up){
    color: $blue-12;
    font-size: 1.5rem;
  }
}

.videoResult{
  max-width: 25.5rem;
  margin: 0 auto;
}
.videoWrapper{
  position: relative;
  @extend .videoResult;
  height: 222px;
  video{
    width: 100%;
    display: block;
  }
  .video{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}

.btnClose{
  &:global(.btn){
    width: 1.25rem;
    height: 1.25rem;
    border: .0625rem solid $white;
    background-color: $dark;
    color: $white;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    padding: 0;
    transform: translate(50%,-50%);
    font-size: .625rem;
    .close{
      font-size: 1rem;
    }
    &:hover, &:focus, &:active, &:active:focus{
      border-color: $white;
      background-color: $dark;
      color: $white;
    }
  }
}
.btnPlay{
  &:global(.btn){
    @extend %absoluteCenter;
    font-size: 3rem;
    color: $gray-18;
    z-index: 2;
    line-height: 1;
    &:hover, &:focus, &:active, &:active:focus{
      color: $gray-18;
    }
  }
}

.videoTab{
  :global(.MuiCard-root){
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  :global(.ql-container){
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
}

.recordCircle{
  &::before{
    content: '';
    width: 1rem;
    height: 1rem;
    border: .0625rem solid $dark;
    border-radius: 50%;
    margin-right: .5rem;
  }
}