.error {
  color: $danger;
}

.form-control {
  width: 100%;
  background: $white;
  border: .0625rem solid $gray-1001;
  height: 2.5rem;
  border-radius: .375rem;
  padding: .375rem 1rem;
  font-size: $font-size-base;
  color: $dark;
  &:not([readonly]):focus{
      border: .0625rem solid $primary;
  }
  @include placeholder($gray-1003, 400);
  &.form-control-sm{
    height: 2.5rem;
    font-size: $font-size-sm;
  }
  &.form-control-lg{
    height: 3.125rem;
  }
  &.text-area{
    height: toRem(100);
  }
  &.error {
    border-color: $danger;
  }
}

.form-group{
  margin-bottom: 1rem;
}

.form-label{
  display: inline-block;
  margin-bottom: .25rem;
  font-size: $font-size-sm;
}

.hidden-input{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}

.upload-box{
  position: relative;
  border: .06255rem solid $gray-1001;
  padding: .5rem;
}

.toggle-btn-group{
  .MuiToggleButton-root{
    border-radius: 50px;
    min-width: 7.5rem;
    border-color: $gray-1001;
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin: 0;
    border-left-color: $gray-1001;
  }
}

.number-icon-hidden::-webkit-outer-spin-button,.number-icon-hidden::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

//custom checkbox and radio
 %custom-control-input{
   z-index: 1;
   opacity: 0;
   position: absolute;
   left: 0;
   top: 0;
   width: 1.5rem;
   height: 1.5rem;
 }
%custom-control-label-before{
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  pointer-events: none;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  border: .0625rem solid rgba($blue-12,.25);
  background-color: $gray-14;
}

%custom-control-label{
  padding-left: 2rem;
  line-height: 1.5rem;
  font-weight: $font-weight-medium;
  color: $black-11;
}
.custom-checkbox {
  padding: 0;
  position: relative;
  .custom-control-input {
    @extend %custom-control-input;
    + .custom-control-label {
      @extend %custom-control-label;
      &:before {
        @extend %custom-control-label-before;
        border-radius: 3px;
      }
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: .5rem;
        top: 0.1875rem;
        width: 0.375rem;
        height: 0.875rem;
        border-style: solid;
        border-width: 0 0.125rem 0.125rem 0;
        border-color: $white;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 0;
      }
    }
    &:not(:disabled) {
      &:active {
        ~ .custom-control-label {
          &:before {
            border-color: rgba($blue-12,.25);
            background-color: $gray-14;
          }
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        color: $black;
        &:before {
          border-color: $blue-12;
          background-color: $blue-12;
        }
        &:after {
          background-image: none;
          display: block;
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &:before {
          box-shadow: none;
        }
      }
      &:not(:checked) {
        ~ .custom-control-label {
          & ~ .custom-control-label {
            & ~ .custom-control-label::before {
              border-color: rgba($blue-12,.25);
            }
          }
        }
      }
    }
    &[disabled],
    &:disabled {
      ~ .custom-control-label {
        color: rgba($black, 0.4);
        &:before {
          border-color: rgba($black, 0.4);
          background-color: $white;
        }
      }
    }
  }
  &-sm{
    .custom-control-input{
      + .custom-control-label{
        font-size: toRem(14);
        line-height: toRem(18);
        &:before{
          width: toRem(18);
          height: toRem(18);
        }
        &:after{
          left: toRem(6);
          top: toRem(2);
          width: toRem(5);
          height: toRem(12);
        }
      }
    }
  }
}

.custom-radio {
  padding: 0;
  position: relative;
  .custom-control-input {
    @extend %custom-control-input;
    + .custom-control-label {
      @extend %custom-control-label;
      &:before {
        @extend %custom-control-label-before;
        border-radius: 50%;
      }
      &:after {
        content: '';
        position: absolute;
        background-color: $blue-12;
        display: none;
        left: .4375rem;
        top: .4375rem;
        width: .625rem;
        height: .625rem;
        border-radius: 50%;
      }
    }
    &:not(:disabled) {
      &:active {
        ~ .custom-control-label {
          &:before {
            border-color: rgba($blue-12, .25);
            background-color: $white;
          }
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        color: $black-11;
        &:before {
          border-color: rgba($blue-12,.25);
          background-color: $gray-14;
        }
        &:after {
          background-image: none;
          display: block;
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &:before {
          box-shadow: none;
        }
      }
      &:not(:checked) {
        ~ .custom-control-label {
          & ~ .custom-control-label {
            & ~ .custom-control-label::before {
              border-color: rgba($blue-12,.25);
            }
          }
        }
      }
    }
    &[disabled],
    &:disabled {
      ~ .custom-control-label {
        color: rgba($black-11, 0.4);
        &:before {
          border-color: rgba($blue-12,.25);
          background-color: $white;
        }
      }
    }
  }
}

.switch-btn{
  .MuiSwitch-sizeMedium{
    width: 42px;
    height: 26px;
    padding: 0;
  }
  .MuiFormControlLabel-label{
    font-weight: $font-weight-medium;
  }
}

.search-with-icon{
  position: relative;
  .search-icon{
    @extend %absoluteVCenter;
    left: .875rem;
    margin-top:toRem(-2);
  }
  .form-control-sm{
    padding-left: 2.5rem;
  }
  .search-input{
    width: toRem(250);
  }
}

.upload-circle{
  width: 5.875rem;
  height: 5.875rem;
  border: .0625rem solid rgba($gray-17,.6);
  border-radius: 50%;
  padding: 1.25rem;
  position: relative;
  &::before{
    content: '';
    @extend %absoluteCenter;
    width: calc(100% - 1.25rem);
    height: calc(100% - 1.25rem);
    border: 0.1875rem solid rgba($gray-13, .5);
    border-radius: 50%;
  }
  :global(.icon-arrow-solid-up){
    color: $blue-12;
    font-size: 1.5rem;
  }
}