@import "src/assets/themes/user-variables";

.skillTitle{
  font-size: 15px;
}
.videoList{
  li{
    padding: .625rem 0;
    border-bottom: .0625rem solid $gray-12;
  }
}
.trashWrap{
  min-width: 3rem;
}
.introOutroWrap{
  min-width: 200px;
}