@import "../../../assets/themes/user-variables";
@import "src/assets/themes/mixins";

.statusbar{
  background-color: $white;
  padding: toRem(8) 0;
  border-bottom: toRem(1) solid $gray-1000;
}

.progressStep{
  color: $gray-11;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 .5rem;
  &.active{
    .progressIcon{
      color: $primary;
    }
    .stepInfo{
      color: $dark;
    }
    &::before{
      background-color: $gray-11;
    }
  }
  &.done{
    &::before{
      background-color: $gray-11;
    }
  }
  &::before{
    content: '';
    position: absolute;
    height: 3px;
    width: calc(100% - 2rem);
    top: .375rem;
    right: calc(50% + 1rem);
    background-color: $black-15;
  }
  &:first-child{
    &::before{
      display: none;
    }
  }
}

.progressIcon{
  display: flex;
  margin-bottom: .5rem;
}

.stepInfo{
  font-size: 0.8125rem;
}

@media (min-width: 576px) {
  .progressStep{
    padding: 0 1rem;
  }
}

@media (min-width: 1024px) {
  .progressStep{
    min-width: 10rem;
  }
}

@media (min-width: 1200px) {
  .progressStep{
    min-width: 12rem;
  }
}