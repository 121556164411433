@font-face {
  font-family: 'icomoon';
  src:  url('#{$path-to-fonts}/Icomoon/icomoon.eot?62fj0h');
  src:  url('#{$path-to-fonts}/Icomoon/icomoon.eot?62fj0h#iefix') format('embedded-opentype'),
  url('#{$path-to-fonts}/Icomoon/icomoon.ttf?62fj0h') format('truetype'),
  url('#{$path-to-fonts}/Icomoon/icomoon.woff?62fj0h') format('woff'),
  url('#{$path-to-fonts}/Icomoon/icomoon.svg?62fj0h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sort:before {
  content: "\e911";
}
.icon-img-upload:before {
  content: "\e910";
}
.icon-eye:before {
  content: "\e90e";
}
.icon-clock-time:before {
  content: "\e90f";
}
.icon-globe:before {
  content: "\e90a";
}
.icon-camera:before {
  content: "\e90d";
}
.icon-clock-circle:before {
  content: "\e909";
}
.icon-ellipse-uncheck:before {
  content: "\e90b";
}
.icon-ellipse-check:before {
  content: "\e90c";
}
.icon-grid:before {
  content: "\e908";
}
.icon-video-solid:before {
  content: "\e907";
}
.icon-arrow-solid-up:before {
  content: "\e906";
}
.icon-code:before {
  content: "\e900";
}
.icon-document:before {
  content: "\e901";
}
.icon-paper-upload:before {
  content: "\e902";
}
.icon-plus:before {
  content: "\e903";
}
.icon-video:before {
  content: "\e904";
}
.icon-category:before {
  content: "\e905";
}
