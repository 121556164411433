@import "../../../../assets/themes/user-variables";
@import "src/assets/themes/mixins";

.questionContent{
  position: relative;
  z-index: 1;
  padding-left: toRem(24);
  .iconImg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.imgShapes{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right;
}

.infoCard{
  position: relative;
  &:global(.MuiCard-root){
    background-color: $snow-12;
  }
}