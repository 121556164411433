@import "src/assets/themes/user-variables";

.iconWrap{
  width: 3.375rem;
  height: 3.375rem;
  border-radius: .375rem;
  background-color: $white;
  overflow: hidden;
  border: .0625rem solid $white;
  padding: .625rem;
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.multiSelectMenu{
  min-width: 250px;
}

.iconClose{
  &:global(.MuiChip-deleteIcon){
    font-size: 1rem !important;
  }
}