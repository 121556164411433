.card{
  border-color: #E4E6EF;
  &.rounded-6{
    border-radius: .375rem;
  }
  &-md{
    &:last-child{
      .MuiCardContent-root{
        padding-bottom: toRem(24);
      }
    }
    .MuiCardContent-root{
      padding: toRem(30) toRem(24);
    }
  }
}
.card-dashed{
  &.MuiCard-root{
    border: .0625rem dashed $gray-15;
    border-radius: .375rem;
    background-color: rgba($gray-14, .4);
  }
}

.card-table {
  &.MuiCard-root{
    border: toRem(1) solid $gray-1001;
    border-radius: toRem(12);
  }
}
@media  (min-width: 768px){
  .card{
    &-lg{
      > .MuiCardContent-root{
        padding: toRem(50) toRem(100);
        &:last-child{
          padding-bottom: toRem(50);
        }
      }
    }
    &-md{
      &:last-child{
        .MuiCardContent-root{
          padding-bottom: toRem(50);
        }
      }
      .MuiCardContent-root{
        padding: toRem(50);
      }
    }
  }
}
