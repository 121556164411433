@import "src/assets/themes/user-variables";

.breadcrumb{
  background-color: $black-13;
  padding: 15px 0;
}

.breadcrumbList{
  li{
    color: $black-14;
    font-size: 0.8125rem;
    font-weight: $font-weight-medium;
    &::after{
      content: '/';
      margin: 0 .5rem;
    }
    &:last-child{
      color: $white;
      &::after{
        display: none;
      }
    }
  }
}