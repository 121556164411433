@import "src/assets/themes/user-variables";
@mixin bordered($color) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #464e5f;

  &::before {
    content: "O";
    top: 0;
    left: 15px;
    margin-right: 10px;
    color: $color;
    transform: scale(2.5);
  }
}

@mixin card() {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
  border-radius: 12px;
  padding: 30px;
}

@mixin imgStyle() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f3f6f9;
  border-radius: 12px;

  img {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: auto;
      padding: 0 10px;
    }
  }
}

.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f8fa;
  // height: 100vh;
  padding: 40px 0;

  .icon {
    width: 32px;
    height: 32px;
    background: #f3f6f9;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
  }

  h2 {
    color: #212121;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }

  h6 {
    color: #b5b5c3;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    white-space: nowrap;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 5px 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .headerTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      max-width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    .filterSection {
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
      .filterBtn {
        height: 34px;
        background: #ffffff;
        border-radius: 6px;
        span {
          margin: 0;
          padding: 10px 14px;
        }
      }
    }
  }

  .card {
    @include card();
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 40px 0;

    table {
      
      thead {
        th {
          // padding: 0.75rem 0;
          background-color: #ffffff;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 400;
          text-align: left;
          text-transform: uppercase;
          border-bottom-width: 1px;
          border-color: #b5b5c3;

          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 2;
        }
        .action {
          padding: 0.75rem 0;
          background-color: #ffffff;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 400;
          text-align: right;
          text-transform: uppercase;
          border-bottom-width: 1px;
          border-color: #b5b5c3;
        }
      }

      td {
        border-bottom-width: 1px;
        border-color: #b5b5c3;

        h6 {
          margin: 0 !important;
        }

        .icons {
          span {
            color: #b5b5c3 !important;
            font-family: $font-family-base;
            font-style: normal;
            margin: 0 12px 0 6px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
          }

          display: flex;
          flex-direction: row;
          gap: 5px;

          .tableInfo {
            display: flex;
            flex-direction: row;
            gap: 5px;
          }
        }

        p {
          font-family: $font-family-base;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #464e5f;
        }

        .avatar {
          @include imgStyle();
        }
      }
    }
  }

  .saveButton {
    padding: 10px 27px;
    background: #3699ff;
    border-radius: 6px;
    margin-left: 10px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;

    @media (max-width: 768px) {
      padding: 10px 15px;
    }
  }
}

button {
  border-style: none;
  cursor: pointer;
}

.tableRow{
  &:hover{
    background-color: #c8c3f2;
    cursor: pointer;
    // color: #ffffff;
  }
}
