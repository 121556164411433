.custom-pagination{
  .MuiPaginationItem-root{
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    color: $secondary;
    border-color: $gray-1001;
    &.MuiPaginationItem-textPrimary{
      &.Mui-selected{
        color: $white;
        background-color: $primary;
      }
    }
  }
  .MuiPaginationItem-previousNext{
    border-color: $white;
    background-color: $white;
  }
}