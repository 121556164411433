@import "assets/themes/theme.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  display: block;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-toggler-icon {
  background: #e0e0e0;
  border-radius: 2px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: black gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f5f8fa;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  // border: 1px solid black;
}
