@import "src/assets/themes/user-variables";

.editorWrap {
  position: relative;
  padding: 0;
  :global(.ql-container) {
    min-height: 100px;
  }
  :global(.ql-toolbar.ql-snow) {
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    border-color: $gray-12;
    padding: 0.875rem 17.5rem 0.875rem 0.5rem;
  }
  :global(.ql-editor) {
    min-height: 100px;
    max-height: 600px;
    font-size: $font-size-base !important;
    font-family: $font-family-base !important;
    overflow: auto;
    p{
      font-family: $font-family-base !important;
    }
  }
  :global(.ql-container) {
    min-height: 100px;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-color: $gray-12;
    font-size: $font-size-base !important;
    font-family: $font-family-base !important;
  }  
  :global(.ql-editor.ql-blank::before) {
    min-height: 100px;
    color: $gray-13;
    font-style: normal;
    font-size: $font-size-base !important;
    font-family: $font-family-base !important;
  }
}
.editorAction {
  position: absolute;
  top: 0.6rem;
  right: 1rem;
}
.practiceAction {
  position: absolute;
  top: 0.5rem;
  right: 8rem;
}
.btnAttachFile {
  overflow: hidden;
  &:global(.btn-light) {
    background-color: $gray-14;
    color: $gray-13;
    &:hover {
      background-color: $gray-14;
      border-color: $gray-14;
    }
  }
}
